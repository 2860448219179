import * as React from "react";

import Form, { FormComponentProps } from "@ant-design/compatible/es/form";
import { Button, CardFooter } from "@imperocom/ui";
import { InputRef, Input, Spin } from "antd";
import axios, { AxiosError } from "axios";

import { APIRoute } from "routes";
import withI18n, { t } from "utils/i18n";
import { SMSStatusPoller, getStatusMessage, getErrorMessage, newSMSStatusPoller, startSMSStatusPoller, stopSMSStatusPoller } from "utils/sms";


import theme from "./theme.module.scss";

const FormItem = Form.Item;

/*
 * SMS status poller
 */
class Poller extends React.Component<PollerProps> {
    constructor(props: PollerProps) {
        super(props);
        if (props.poller) {
            startSMSStatusPoller(props.poller);
        }
    }
    shouldComponentUpdate(nextProps: PollerProps) {
        if (this.props.poller !== nextProps.poller) {
            if (this.props.poller) {
                stopSMSStatusPoller(this.props.poller);
            }
            if (nextProps.poller) {
                startSMSStatusPoller(nextProps.poller);
            }
        }
        return false;
    }
    componentWillUnmount() {
        if (this.props.poller) {
            stopSMSStatusPoller(this.props.poller);
        }
    }
    render() {
        return null;
    }
}

interface PollerProps {
    poller?: SMSStatusPoller,
}


/*
 * Pin code form
 */
class PinCodeForm extends React.Component<PinCodeFormProps, PinCodeFormState> {
    private pinInput: React.RefObject<InputRef>;

    constructor(props: PinCodeFormProps) {
        super(props);
        this.pinInput = React.createRef();

        this.state = {
            smsStatusMessage: undefined,
            poller: undefined,
            wrongPin: false,
        };
        this.sendSMS();
    }

    componentDidMount() {
        this.focusFirstInput();
    }

    focusFirstInput() {
        if (this.pinInput.current) { this.pinInput.current.focus(); }
    }

    componentDidUpdate(prevProps: PinCodeFormProps) {
        if (prevProps.wrongPin !== this.props.wrongPin) {
            this.setState({ wrongPin: this.props.wrongPin }, () => {
                this.props.form.validateFields(["pin"], { force: true });
                if (this.props.wrongPin) {
                    this.sendSMS();
                }
            });
        }
    }

    render() {
        const { children, submitButtonText } = this.props;
        const { getFieldDecorator } = this.props.form;
        const { smsStatusMessage, poller } = this.state;

        const submitButton = (
            <Button
                id="resendpin-link"
                type="link"
                onClick={this.resendSMS}>
                {t("Login_ResendPIN")}
            </Button>);

        return (
            <div className={theme.pinCodeContainer}>
                <Poller poller={poller} />
                <Form onSubmit={this.handleSubmit} className={theme.pinCodeForm}>
                    <FormItem>
                        {getFieldDecorator("pin", {
                            rules: [{ validator: this.validatePin }, { validator: this.validatePinServerSide }],
                        })(
                            <Input.Group className={theme.pinCodeInputGroup}>
                                {getFieldDecorator("pin1")(<Input className={theme.pinInput} maxLength={1} onKeyUp={this.handlePinInput} ref={this.pinInput} />)}
                                {getFieldDecorator("pin2")(<Input className={theme.pinInput} maxLength={1} onKeyUp={this.handlePinInput} />)}
                                {getFieldDecorator("pin3")(<Input className={theme.pinInput} maxLength={1} onKeyUp={this.handlePinInput} />)}
                                {getFieldDecorator("pin4")(<Input className={theme.pinInput} maxLength={1} onKeyUp={this.handlePinInput} />)}
                            </Input.Group>
                        )}
                    </FormItem>

                    <Button
                        id="two-factor-button"
                        type="primary"
                        htmlType="submit"
                        className={this.state.wrongPin ? " wrong-pin" : "" + theme.pinCodeButton}
                        block
                    >
                        {submitButtonText}
                    </Button>
                    <span id="sms-status">{<Spin className={theme.pinCodeSpinner} tip={smsStatusMessage} /> || ""}</span>
                </Form>
                {children ?
                    <CardFooter>{submitButton}{children}</CardFooter> :
                    <CardFooter>{submitButton}</CardFooter>}
            </div>
        );
    }

    emptyPin = () => {
        this.props.form.setFieldsValue({
            pin1: "",
            pin2: "",
            pin3: "",
            pin4: "",
        });
    };

    resendSMS = (e: React.MouseEvent) => {
        e.preventDefault();
        this.emptyPin();

        this.sendSMS();
        this.focusFirstInput();
    };

    sendSMS = () => {
        // TODO: Migrate to AjaxManager once headers are supported
        axios.post(`${APIRoute.ROOT}/send-pin`, { challenge: this.props.challenge })
            .then(response => {
                if (response.status === 202) {
                    const smsStatusMessage = getStatusMessage(response.headers["x-impero-smsstatus"] as string | null);
                    this.setState({
                        smsStatusMessage,
                        poller: newSMSStatusPoller(
                            `${APIRoute.ROOT}/sms-status`,
                            smsStatusMessage => {
                                this.setState({
                                    smsStatusMessage,
                                });
                            },
                            smsStatusMessage => {
                                this.setState({
                                    smsStatusMessage,
                                });
                            },
                            errorMessage => {
                                this.setState({
                                    smsStatusMessage: errorMessage,
                                });
                            }
                        ),
                    });
                }
            })
            .catch((err: AxiosError) => {
                const messageFromHeader = err.response?.headers["x-impero-smserrorstatus"] as string | null;
                if (messageFromHeader !== null && messageFromHeader !== undefined) {
                    this.setState({
                        smsStatusMessage: getErrorMessage(messageFromHeader),
                        poller: undefined,
                    });
                } else {
                    this.setState({
                        smsStatusMessage: t("SMS_UnknownError"),
                        poller: undefined,
                    });
                }
            });
    };

    handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        this.setState({ wrongPin: false }, () => {
            this.props.form.validateFields((err: any, values: PinCodeFormProps) => {
                if (!err) {
                    const { pin1, pin2, pin3, pin4 } = values;
                    if (pin1 && pin2 && pin3 && pin4) {
                        this.props.onSubmitPin(pin1 + pin2 + pin3 + pin4);
                        this.focusFirstInput();
                    }
                }
            });
        });
    };

    handlePinInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const keyString = String.fromCharCode(e.keyCode);

        if ((/[a-z0-9]/i).test(keyString)) {
            const { form } = this.props;
            const input = e.currentTarget;
            const next = input.nextElementSibling as HTMLElement;
            if (next) {
                next.focus();
            } else if (form.getFieldValue("pin1") && form.getFieldValue("pin2") && form.getFieldValue("pin3") && form.getFieldValue("pin4")) {
                this.handleSubmit(e);
            }
        }
    };

    validatePin = (rule: any, value: any, callback: Function) => {
        const { form } = this.props;
        if (form.getFieldValue("pin1") && form.getFieldValue("pin2") && form.getFieldValue("pin3") && form.getFieldValue("pin4")) {
            callback();
        } else {
            callback(t("Login_PinCodeOrRecoveryCode"));
        }
    };

    validatePinServerSide = (rule: any, value: any, callback: Function) => {
        if (this.state.wrongPin) {
            this.emptyPin();
            callback(t("Login_IncorrectOrExpiredPin"));
        } else {
            callback();
        }
    };
}

interface PinCodeFormProps extends FormComponentProps {
    pin1?: string,
    pin2?: string,
    pin3?: string,
    pin4?: string,
    wrongPin: boolean,
    onSubmitPin: (pin: string) => void,
    onSwitchToRecovery?: () => void,
    submitButtonText: string,
    children?: any,
    challenge: string | undefined,
}

interface PinCodeFormState {
    smsStatusMessage?: string,
    wrongPin: boolean,
    poller?: SMSStatusPoller,
}

const WrappedPinCodeForm = Form.create<PinCodeFormProps>()(withI18n<PinCodeFormProps>(PinCodeForm));

export {
    WrappedPinCodeForm as PinCodeForm,
};

