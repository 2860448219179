import zxcvbn from "zxcvbn";

import { OrgCookie } from "utils/orgCookie";
import { translateFeedback } from "utils/translateZxcvbn";

export function buildUserDictionary(cookie: OrgCookie, user: User | undefined) {
    const userDictionary = [
        "impero",
        "database",
        "global",
        cookie.name,
        cookie.shortName,
    ];

    if (user) {
        userDictionary.push(user.email);
        userDictionary.push(user.firstName);
        userDictionary.push(user.lastName);
    }

    return userDictionary;
}

export type Callback = (errors?: Array<Error> | undefined) => void;

export function confirmPassword(value: string, minStrength: number, userDictionary: Array<string>): Array<Error> | undefined {
    const checkRes = zxcvbn(value, userDictionary);
    if (checkRes.score < minStrength) {
        const errors = [];
        const errorStr = translateFeedback(checkRes.feedback);

        errors.push(new Error(errorStr));

        return errors;
    } else {
        return undefined;
    }
}

export function confirmGlobalPassword(rule: any, value: string, callback: Callback, userDictionary: Array<string>) {
    const errors = confirmPassword(value, 4, userDictionary);
    callback(errors);
}

export function confirmNormalPassword(rule: any, value: string, callback: Callback, userDictionary: Array<string>) {
    const errors = confirmPassword(value, 3, userDictionary);
    callback(errors);
}


interface User {
    email: string,
    lastName: string,
    firstName: string,
}
