import * as React from "react";

import Form, { FormComponentProps } from "@ant-design/compatible/es/form";
import { LockOutlined } from "@ant-design/icons";
import { FormSubtitle, FormTitle, Button } from "@imperocom/ui";
import { Input, Spin } from "antd";
import axios from "axios";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import PhoneInput from "react-phone-number-input";
import flags from "react-phone-number-input/flags";

import FormCard from "components/formCard";
import FormCardContainer from "components/formCardContainer";
import RightSideIcon from "components/rightSideIcon";
import { DisplayRecoveryCode } from "components/twoFactor/displayRecovery";
import passwordSvg from "img/password-login.svg";
import { APIRoute } from "routes";
import { SetupData } from "types/user/two_factor";
import { PostSetupData } from "types/web/org/two_factor";
import { AjaxManager } from "utils/ajax";
import { IMPERO_WWW_AUTHENTICATION_LOGIN, IMPERO_X_RECOVERY_CODE } from "utils/auth";
import withI18n, { t } from "utils/i18n";
import { emitNotification } from "utils/notification";
import { assertNever } from "utils/obj";
import { OrgCookie } from "utils/orgCookie";
import { OrgProfileCookie } from "utils/profileCookie";
import { loginRedirectToRoot } from "utils/url";

import { PinCodeForm } from "./pinCode";
import theme from "./theme.module.scss";
import "react-phone-number-input/style.css";

const FormItem = Form.Item;

class Setup2FAForm extends React.Component<Setup2FAFormProps> {
    render() {
        const { form, phoneNumber, orgProfileCookie } = this.props;
        const { getFieldDecorator } = form;
        return (
            <>
                <FormTitle title={t("Setup2FA_Title")} />
                <FormSubtitle subtitle={t("Setup2FA_Description")} />
                <FormCard>
                    <FormItem className={theme.phoneInputContainer}>
                        {getFieldDecorator("phoneNumber", {
                            rules: [{ required: true, message: t("Setup2FA_PhoneNumberMissing") }],
                            initialValue: phoneNumber,
                        })(
                            <PhoneInput
                                className="ant-phone-number-input"
                                numberInputProps={{ className: `ant-input ${theme.phoneInput}` }}
                                defaultCountry="DK"
                                displayInitialValueAsLocalNumber={true}
                                flags={flags}
                                onChange={() => {}}
                            />
                        )}
                    </FormItem>
                    {
                        orgProfileCookie ?
                            <FormItem>
                                {getFieldDecorator("password", {
                                    rules: [{ required: true, message: t("Login_PasswordRequired") }],
                                })(
                                    <Input prefix={<LockOutlined className={theme.iconOpacity} />} type="password" placeholder={t("Login_Password")} />
                                )}
                            </FormItem>
                            : null
                    }
                </FormCard>
            </>
        );
    }
}

interface Setup2FAFormProps extends FormComponentProps {
    phoneNumber?: string,
    verified: boolean,
    orgProfileCookie: OrgProfileCookie | null,
}

const WrappedSetup2FAForm = Form.create<Setup2FAFormProps>()(Setup2FAForm);

type RecoveryCodeHeaderContent = { type: "NO_RECOVERY_CODE" } | { type: "RECOVERY_CODE", recoveryCode: string };

class Setup2FA extends React.Component<Setup2FAProps, Setup2FAState> {
    formRef: Setup2FAForm | null = null;

    constructor(props: Setup2FAProps) {
        super(props);
        this.state = {
            status: { type: "DEFAULT" },
            setupData: undefined,
            verifying: false,
            wrongPin: false,
        };
    }

    componentDidMount() {
        this.renewSetupData();
    }

    renewSetupData = () => {
        const { ajaxManager } = this.props;
        return ajaxManager.ajax<SetupData, Setup2FAStatus>(
            () => axios.get(APIRoute.SETUP_2FA), {
                component: this,
                initialStatusValue: "DEFAULT",
                getErrorStatus: () => ({ type: "DEFAULT" }),
                getSuccessStatus: () => ({ type: "DEFAULT" }),
                inFlightStatus: { type: "FETCHING_DATA" },
            })
            .then(setupData => this.setState({ setupData }));
    };

    saveFormRef = (formRef: (Setup2FAForm | null)) => {
        this.formRef = formRef;
    };

    render() {
        const { status, setupData, verifying, wrongPin } = this.state;
        if (!setupData) {
            return <Spin />;
        }
        const { loggedIn, orgCookie, orgProfileCookie } = this.props;
        switch (status.type) {
            case "DISPLAY_RECOVERY": {
                return <DisplayRecoveryCode recoveryCode={status.recoveryCode} orgCookie={orgCookie} />;
            }
            default: {
                return (
                    <FormCardContainer
                        fullWidth={loggedIn ? true : false}
                        rightSide={loggedIn ? undefined : <RightSideIcon icon={passwordSvg} customImages={true} orgCookie={orgCookie} />}
                        showLogo={!loggedIn}>
                        <WrappedSetup2FAForm
                            wrappedComponentRef={this.saveFormRef}
                            phoneNumber={setupData.phoneNumber ? "+" + setupData.phoneNumber.prefix + setupData.phoneNumber.number : undefined}
                            verified={setupData.verified}
                            orgProfileCookie={orgProfileCookie}
                        />
                        {verifying ? (
                            <PinCodeForm onSubmitPin={this.handleSubmitPin} submitButtonText={t("Login_ConfirmButton")} wrongPin={wrongPin} challenge={this.props.challenge} />
                        ) : (
                            <>
                                <span className={theme.setup2FAButton}>
                                    <Button id="use-number" type="primary" onClick={this.handleSubmit} block>{t("Setup2FA_Button")}</Button>
                                </span>
                            </>
                        )}
                    </FormCardContainer>
                );
            }
        }
    }

    handleSubmit = () => {
        if (!this.formRef) {
            return;
        }
        const { form } = this.formRef.props;
        form.validateFields((err: any, values: any) => {
            if (err) {
                return;
            }

            const { phoneNumber, password }: { phoneNumber: string, password: string } = values;
            const parsed = parsePhoneNumberFromString(phoneNumber);
            if (parsed === undefined) {
                return;
            }

            const submission: PostSetupData = {
                prefix: parsed.countryCallingCode as string,
                number: parsed.nationalNumber as string,
                password,
                // The x-impero-requireshumanverification header is not handled here
                // Should not be necessary since we're setting up our 2FA (as opposed to updating it)
                captcha: null,
                challenge: this.props.challenge ?? null,
            };

            const { ajaxManager } = this.props;
            ajaxManager.ajax<SetupData, Setup2FAStatus>(() => axios.post(APIRoute.SETUP_2FA, submission), {
                component: this,
                initialStatusValue: "DEFAULT",
                getSuccessStatus: () => ({ type: "DEFAULT" }),
                getErrorStatus: () => ({ type: "DEFAULT" }),
                inFlightStatus: { type: "SUBMITTING" },
                expectedStatus: [200],
                onErrorCode: {
                    401: e => {
                        const authType = e.response && e.response.headers["www-authenticate"] || undefined;
                        switch (authType) {
                            case IMPERO_WWW_AUTHENTICATION_LOGIN:
                                form.setFields({
                                    password: {
                                        value: "",
                                        errors: [new Error(t("Login_UsernameOrPasswordIncorrect"))],
                                    },
                                });
                                break;
                            default:
                                throw new Error(t("Login_UnknownAuthType") + ": " + authType);
                        }
                    },
                },
            })
                .then(data => {
                    if (data.verified) {
                        // If the phone number is verified, we won't display a recovery code
                        this.handlePinValidated({ type: "NO_RECOVERY_CODE" });
                    } else {
                        this.setState({
                            verifying: true,
                        });
                    }
                });
        });
    };

    // TODO: Migrate to AJAX manager when it supports headers in the happy path
    handleSubmitPin = (pin: string) => {
        // Avoid double-sending the query
        if (this.state.status.type !== "DEFAULT") {
            return;
        }

        this.setState({
            wrongPin: false,
            status: { type: "VALIDATING_PIN" },
        });

        axios.post<{}>(`${APIRoute.ROOT}/validate-pin`, { pin, challenge: this.props.challenge })
            .then(res => {
                const recoveryCode = res.headers[IMPERO_X_RECOVERY_CODE];
                const headerContent: RecoveryCodeHeaderContent = recoveryCode !== undefined ? { type: "RECOVERY_CODE", recoveryCode } : { type: "NO_RECOVERY_CODE" };
                this.handlePinValidated(headerContent);
            })
            .catch(_ => {
                this.setState({ wrongPin: true, status: { type: "DEFAULT" } });
            });
    };

    handlePinValidated = (recoveryCodeHeader: RecoveryCodeHeaderContent) => {
        this.setState({ status: { type: "DEFAULT" } });
        emitNotification("success", t("Setup2FA_UpdateSuccess"));

        switch (recoveryCodeHeader.type) {
            case "NO_RECOVERY_CODE":
                if (this.props.loggedIn) {
                    setTimeout(loginRedirectToRoot, 2000);
                } else {
                    this.renewSetupData();
                }
                break;
            case "RECOVERY_CODE":
                this.setState({
                    status: { type: "DISPLAY_RECOVERY", recoveryCode: recoveryCodeHeader.recoveryCode },
                });
                break;
            default:
                assertNever(recoveryCodeHeader);
        }
    };
}


interface Setup2FAProps {
    loggedIn: boolean,
    ajaxManager: AjaxManager,
    challenge: string | undefined,
    orgCookie: OrgCookie,
    orgProfileCookie: OrgProfileCookie | null,
}

type Setup2FAStatus = { type: "DEFAULT" } | { type: "FETCHING_DATA" } | { type: "SUBMITTING" } | { type: "VALIDATING_PIN" } | { type: "DISPLAY_RECOVERY", recoveryCode: string };

interface Setup2FAState {
    status: Setup2FAStatus,
    setupData: SetupData | undefined,
    wrongPin: boolean,
    verifying: boolean,
}

const LocalizedSetup2FA = withI18n<Setup2FAProps>(Setup2FA);

export {
    LocalizedSetup2FA as Setup2FA,
    PinCodeForm,
};

