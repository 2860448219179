import axios, { AxiosError } from "axios";
import i18n from "i18next";

// locize-use-prefix SMS_

const UNKNOWN_STATUS_MESSAGE = i18n.t("SMS_UnknownStatus");
const UNKNOWN_ERROR_STATUS_MESSAGE = i18n.t("SMS_UnknownStatus");

const STATUS_TO_MESSAGE: {[status: string]: string} = {
    Sent: "SMS_Sent",
    Failed: "SMS_Failed",
    Undelivered: "SMS_Undelivered",
    Delivered: "SMS_Delivered",
    Queued: "SMS_Sending",
    Sending: "SMS_Sending",
};

const ERROR_STATUS_TO_MESSAGE: {[status: string]: string} = {
    MessageBlocked: "SMS_FailedMessageBlocked",
    //LandlineOrUnreachableCarrier: "SMS_FailedLandlineOrCarrierBlocked",
    UnreachablePhone: "SMS_FailedUnreachablePhone",
    UnknownError: "SMS_FailedUnknownError",
    TransientError: "SMS_FailedTransientError",
    SevereError: "SMS_FailedSevereError",
    MaybeWrongPhoneNumber: "SMS_FailedMaybeWrongPhoneNumber",
};

function getStatusMessage(status: string|null): string {
    let message;
    if (status) {
        const maybeMessageKey = STATUS_TO_MESSAGE[status];
        if (!maybeMessageKey) {
            message = UNKNOWN_STATUS_MESSAGE + status;
        } else {
            message = i18n.t(maybeMessageKey);
        }
    } else {
        message = UNKNOWN_STATUS_MESSAGE;
    }
    return message;
}

function getErrorMessage(status: string): string {
    const maybeMessageKey = ERROR_STATUS_TO_MESSAGE[status];
    let message;
    if (!maybeMessageKey) {
        message = UNKNOWN_ERROR_STATUS_MESSAGE + status;
    } else {
        message = i18n.t(maybeMessageKey);
    }
    return message;
}

interface SMSStatusPoller {
    url: string,
    pollStartTime: Date,
    stopPolling: boolean,
    onPolling: (smsStatusMessage: string) => void,
    onPollerEnd: (smsStatusMessage: string) => void,
    onError: (errorMessage: string) => void,
}

function newSMSStatusPoller(
    url: string,
    onPolling: (smsStatusMessage: string) => void,
    onPollerEnd: (smsStatusMessage: string) => void,
    onError: (errorMessage: string) => void
): SMSStatusPoller {
    const poller: SMSStatusPoller = {
        url,
        pollStartTime: new Date(),
        stopPolling: false,
        onPolling,
        onPollerEnd,
        onError,
    };
    return poller;
}

function startSMSStatusPoller(poller: SMSStatusPoller) {
    pollForSMSStatus(poller);
}

function stopSMSStatusPoller(poller: SMSStatusPoller) {
    poller.stopPolling = true;
}

function pollForSMSStatus(poller: SMSStatusPoller) {
    const maxPollDuration = 110 * 1000;
    if (new Date().getTime() > poller.pollStartTime.getTime() + maxPollDuration) {
        poller.onPollerEnd(i18n.t("SMS_Timeout"));
        return;
    } else {
        axios // TODO: Migrate to AjaxManager once headers are supported
            .get(poller.url)
            .then(response => {
                if (poller.stopPolling) {
                    return;
                }
                const smsStatus = response.headers["x-impero-smsstatus"] as string | null;
                const smsErrorStatus = response.headers["x-impero-smserrorstatus"] as string | null;
                let smsStatusMessage: string;
                if ((smsStatus === "Failed" || smsStatus === "Undelivered") && smsErrorStatus) {
                    smsStatusMessage = getErrorMessage(smsErrorStatus);
                } else {
                    smsStatusMessage = getStatusMessage(smsStatus);
                }
                if (smsStatus !== "Failed" && smsStatus !== "Undelivered" && smsStatus !== "Delivered") {
                    poller.onPolling(smsStatusMessage);
                    setTimeout(() => {
                        pollForSMSStatus(poller);
                    }, 2000);
                } else {
                    poller.onPollerEnd(smsStatusMessage);
                }
            })
            .catch((err: AxiosError) => {
                // If status is 404, the SMS couldn't be found in the database. Most likely the user has sent it while we were polling for its status. Just stop polling
                if (err.response?.status !== 404) {
                    poller.onError(i18n.t("Login_GenericError"));
                }
            });
    }
}

export {
    getStatusMessage,
    getErrorMessage,
    type SMSStatusPoller,
    newSMSStatusPoller,
    startSMSStatusPoller,
    stopSMSStatusPoller,
};

